import { postApi, putApi, getServiceApi, getNoAuthRFPApi, getApi } from './common/apis';
import axios from 'axios';

export default class AccountMangementService {
    async addNewUser(payload) {
        var userResponse = await postApi(`v1/account`, payload);
        return userResponse;
    }

    async getAccount(accountId) {
        const accountData = await getApi(`v1/account?accountId=${accountId}`);
        return accountData;
    }

    async updateAccredition(payload) {
        const accreditionData = await putApi(`v1/account`, payload);
        return accreditionData;
    }

    async getChannels() {
        const subnetworkData = await getServiceApi(`/api/v1/get-subnetwork`);
        return subnetworkData;
    }

    async getContractTemplate(subnetworkId) {
        const contractTemplateData = await getNoAuthRFPApi(`api/v1/check-channel-template/${subnetworkId}`);
        return contractTemplateData;
    }

    async updateLastLogin(userId) {
        const res = await postApi(`v1/user/update-last-login`, { user_id: userId });
        return res;
    }

    async getPresignedUrl(fileName, contentType) {
        const response = await getApi(`v1/upload-url?is_excel_export=true&file_name=${fileName}&content_type=${contentType}`);
        return response;
    }

    async uploadToS3(presignedUrl, file) {
        const response = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
            }
        });
        return response;
    }

    async triggerBulkUpload(data) {
        const payload = data.subnetworkId ? {
            key: data.key,
            subnetwork_id: data.subnetworkId
        } : {
            key: data.key
        }
        const response = await postApi(`v1/bulk-partner-create`, payload);
        return response;
    }
}
